<template>
  <div>
    <div>
      <div style="height: inherit">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 px-1">
              <div class="row">
                <div class="col-md-3">
                  <div class="card active-">
                    <div class="card-image">
                      <div class="icon-wrapper bg-green">
                        <i class="fa fa-users custom-icon" style="margin: 14px"
                          ><span class="fix-editor">&nbsp;</span></i
                        >
                      </div>
                    </div>
                    <div class="card-info">
                      <div class="text-green">Documents</div>
                      <span class="font-size-10">Management</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div
                    class="card"
                    @click="$router.push('/tags-documents')"
                    style="cursor: pointer"
                  >
                    <div class="card-image">
                      <div class="icon-wrapper bg-yellow">
                        <i class="fa fa-tags custom-icon" style="margin: 14px"
                          ><span class="fix-editor">&nbsp;</span></i
                        >
                      </div>
                    </div>
                    <div class="card-info">
                      <div class="">Tag</div>
                      <span class="font-size-10">Management</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" id="main">
              <div class="card">
                <div class="container table-responsive">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-dark-custom">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Description</th>
                        <th scope="col">Document Type</th>
                        <th scope="col">Config</th>
                        <th scope="col">UID</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(document, index) in documentList"
                        :key="index"
                      >
                        <th scope="row">{{ document.Id }}</th>
                        <td>{{ document.Description }}</td>
                        <td>{{ document.Document_Type }}</td>
                        <td>{{ document.Config }}</td>
                        <td>{{ document.UID }}</td>
                        <td>
                          <b-button
                            size="lg"
                            v-b-modal="'myModal'"
                            user="'index'"
                            @click="showinfo(index)"
                            >View</b-button
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <b-modal id="myModal" size="lg">
                <div class="row">
                  <div class="col-md-4">
                    <div
                      class="card"
                      style="width: 18rem"
                      v-for="(pdf, index) in pdfList"
                      :key="index"
                    >
                      <div class="card-body">
                        <div class="" style="text-align: center">
                          <img
                            src="https://japan.myprism.biz/assets/pdf.png"
                            width="100px"
                            height="100px"
                            style="margin-bottom: 10px"
                          />
                        </div>
                        <h5 class="card-title">{{ pdf.CI_Name }}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">
                          {{ pdf.CI_Class }}
                        </h6>
                        <p class="card-text">{{ pdf.CI_Description }}</p>
                        <a href="#" class="card-link"></a>
                        <button
                          class="btn btn-primary"
                          @click="showinfo_details(index)"
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="" v-if="showinfo_details_pdf">
                      <div
                        v-for="(pdf_details, index) in pdfList_details"
                        :key="index"
                      >
                       <embed
                          v-bind:src="pdf_details.Uri"
                          frameBorder="0"
                          toolbar="0"
                          scroolbar="0"
                          scrolling="auto"
                          height="500"
                          width="500"
                          navpanes=0
                        />
                        <!-- <embed
  src="http://URL_TO_PDF.com/pdf.pdf#toolbar=0&navpanes=0&scrollbar=0"
  width="425" height="500" /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.thead-dark-custom {
  background-color: rgba(243, 242, 247, 1);
  border: none !important;
}
.active- {
  border: 1px solid #00a99c;
}

.bg-purple {
  background-color: rgba(115, 103, 240, 0.12);
}
.bg-red {
  background-color: rgba(234, 84, 85, 0.12);
}
.bg-yellow {
  background-color: rgba(255, 159, 67, 0.12);
}
.bg-green {
  background-color: rgba(0, 207, 232, 0.12);
}
.text-green {
  color: #00a99c;
}
.font-size-10 {
  font-size: 10px;
}
.icon-wrapper {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.card-image {
  position: absolute;
  top: 20px;
  left: 20px;
}
.card-info {
  margin-left: 60px;
}
.card {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
</style>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormRadioGroup,
} from "bootstrap-vue";
import axios from "axios";
import qs from 'qs';
import { password } from '@/@core/utils/validations/validations';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormRadioGroup,
    axios,
    qs

    // vSelect,
  },
  data() {
    return {
      documentList: [],
      bearerToken,
      pdfList: [],
      pdfList_details: [],
      showinfo_details_pdf: false,
      selectedUser: "",
      selectedUser_pdf: "",
    };
  },
  mounted() {
    this.getToken();
    // this.getDocumentList();
  },
  methods: {
     showinfo_details(id){
      this.showinfo_details_pdf = true;
      console.log("ID List",id);
      this.selectedUser_pdf = id;
      console.log("ID Document",this.pdfList[this.selectedUser_pdf].OBID );
      const url = 'https://pedms5.petronas.com/DSR_E1Server/api/v2/SDA/Files('+"'"+this.pdfList[this.selectedUser_pdf].OBID+"'"+')/Intergraph.SPF.Server.API.Model.RetrieveFileUris';


      this.pdfList_details = [];
      this.$axios.get(url, {
         headers: {
              Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik5sZWh4aFB6VWY1V1ZVdkJMN0ZUTVVHZzYycyIsImtpZCI6Ik5sZWh4aFB6VWY1V1ZVdkJMN0ZUTVVHZzYycyJ9.eyJpc3MiOiJodHRwczovL3BlZG1zNS5wZXRyb25hcy5jb20vc2FtL29hdXRoLyIsImF1ZCI6IkZBMDZFODA5LTJBOEMtNDgzRC05MkJBLUNCODE0MkU5RDZDQiIsImV4cCI6MTY1OTY0Mzc3NSwibmJmIjoxNjU5NjAwNTc1LCJjbGllbnRfaWQiOiJURVNUMSIsImluZ3IuY2xpZW50X2lkIjoiYjliNTk0OGMtMjc0My00N2U5LTg4ZTUtYmUyOWY1MjI1YTc1Iiwic2NvcGUiOlsiaW5nci5hcGkiLCJGQTA2RTgwOS0yQThDLTQ4M0QtOTJCQS1DQjgxNDJFOUQ2Q0IiXSwic3ViIjoidXNlcjEiLCJhdXRoX3RpbWUiOjE2NTk2MDA1NzUsImlkcCI6Imlkc3J2IiwibmFtZSI6IjEsIFVzZXIiLCJpbmdyLnVzZXJfaWQiOiJhYzAzNzlkMy1jNDZmLTQ5NjktODFiZi00M2RlYTYwZDRmZjIiLCJyb2xlIjoic3VwZXJ1c2VyIiwiaW5nci5zZXNzaW9uX2lkIjoiZTE2ZWRlM2MtYTcyNC1hOTQ5LWEzYTctZjAwNWJiZGJkOTBlIiwiZW1haWwiOiJsZWUua2ltbG9vbmdAcGV0cm9uYXMuY29tIiwiYW1yIjpbInBhc3N3b3JkIl19.HTvefZQcYF-2KD80iLbSA6xSMSSf60Sy-eHmIi4mYgHCr-loFMYhM82xMDqYhWmRUYmYlWNkL1cfpdv0dmMB8rZTxKLNy_5QpQ4mYYqH4zUA-mYkwsIq1MIFd-Q6OnIg4pT5A6JcY5RHRG30QM4uP2y1PE3jPWcdqBIUzXCItqhnR5PNtB95ojq84ZDiYgSvZ4r1D5WBziYtBqo60OdKphTk3B-L1SfNSTkY8b-elGnVmkB5Mxl3UhZSF9rZwQE9R1Grl7x6hp6nqc-qIv6UeI-BT3h3ZJy0LEyYl3S2P1LS_qtUAauITTBMrYo_JB47jE3cJ5ughV2uX8xuIQ-jnQ`
        },
        
      })

        .then(response => {
        
          console.log(response.data.value);
          this.pdfList_details = response.data.value
          console.log("PDF List", this.pdfList_details);
          // this.documentList = response.data.value
    
        })
         .catch((error) => {
            // this.pdfList_details[0] = "No data for ID: " + this.selectedUser_pdf
            this.$toasted.error('No File For ID:'+this.pdfList[this.selectedUser_pdf].OBID+'')
        }
         );


      

    },
    showinfo(id) {
      this.showinfo_details_pdf = false;
      console.log("ID List", id);
      this.selectedUser = id;
      console.log("ID Document", this.documentList[this.selectedUser].Id);
      const url =
        "https://pedms5.petronas.com/DSR_E1Server/api/v2/SDA/Objects(" +
        "'" +
        this.documentList[this.selectedUser].Id +
        "'" +
        ")/SPFFileComposition_21?$top=100&$count=false";
      console.log("URL", url);

      this.pdfList = [];
      this.$axios
        .get(url, {
          headers: {
            Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik5sZWh4aFB6VWY1V1ZVdkJMN0ZUTVVHZzYycyIsImtpZCI6Ik5sZWh4aFB6VWY1V1ZVdkJMN0ZUTVVHZzYycyJ9.eyJpc3MiOiJodHRwczovL3BlZG1zNS5wZXRyb25hcy5jb20vc2FtL29hdXRoLyIsImF1ZCI6IkZBMDZFODA5LTJBOEMtNDgzRC05MkJBLUNCODE0MkU5RDZDQiIsImV4cCI6MTY1OTY0Mzc3NSwibmJmIjoxNjU5NjAwNTc1LCJjbGllbnRfaWQiOiJURVNUMSIsImluZ3IuY2xpZW50X2lkIjoiYjliNTk0OGMtMjc0My00N2U5LTg4ZTUtYmUyOWY1MjI1YTc1Iiwic2NvcGUiOlsiaW5nci5hcGkiLCJGQTA2RTgwOS0yQThDLTQ4M0QtOTJCQS1DQjgxNDJFOUQ2Q0IiXSwic3ViIjoidXNlcjEiLCJhdXRoX3RpbWUiOjE2NTk2MDA1NzUsImlkcCI6Imlkc3J2IiwibmFtZSI6IjEsIFVzZXIiLCJpbmdyLnVzZXJfaWQiOiJhYzAzNzlkMy1jNDZmLTQ5NjktODFiZi00M2RlYTYwZDRmZjIiLCJyb2xlIjoic3VwZXJ1c2VyIiwiaW5nci5zZXNzaW9uX2lkIjoiZTE2ZWRlM2MtYTcyNC1hOTQ5LWEzYTctZjAwNWJiZGJkOTBlIiwiZW1haWwiOiJsZWUua2ltbG9vbmdAcGV0cm9uYXMuY29tIiwiYW1yIjpbInBhc3N3b3JkIl19.HTvefZQcYF-2KD80iLbSA6xSMSSf60Sy-eHmIi4mYgHCr-loFMYhM82xMDqYhWmRUYmYlWNkL1cfpdv0dmMB8rZTxKLNy_5QpQ4mYYqH4zUA-mYkwsIq1MIFd-Q6OnIg4pT5A6JcY5RHRG30QM4uP2y1PE3jPWcdqBIUzXCItqhnR5PNtB95ojq84ZDiYgSvZ4r1D5WBziYtBqo60OdKphTk3B-L1SfNSTkY8b-elGnVmkB5Mxl3UhZSF9rZwQE9R1Grl7x6hp6nqc-qIv6UeI-BT3h3ZJy0LEyYl3S2P1LS_qtUAauITTBMrYo_JB47jE3cJ5ughV2uX8xuIQ-jnQ`,
          },
        })

        .then((response) => {
          console.log(response.data.value);
          this.pdfList = response.data.value;
          console.log("PDF List", this.pdfList);
          // this.documentList = response.data.value
        });
    },
    getToken() {
      console.log("get Token");
      this.bearerToken;
      const data = {
        'grant_type ': 'password',
        'username' : 'user1',
        'password' : 'A.mshbR1',
        'client_id' : 'TEST1',
        'client_secret' : 'vW({}7QVNA}Up7utI)Ad^59gL7^^-#iZ|V((PPxWaZ{LyYq]+Y_^lN446LlncJ$@',
        'scope' : 'ingr.api',
        'resource' : 'FA06E809-2A8C-483D-92BA-CB8142E9D6CB'

      }
     
      this.$axios
        .post("https://pedms5.petronas.com/sam/oauth/connect/token", qs.stringify(data), {
          headers: {
           'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((response) => {
          console.log(response.data.value);
          this.bearerToken = response.data.value;
          // console.log("Document List", this.documentList);
          // this.documentList = response.data.value
        });
    },
    getDocumentList() {
      console.log("hello");
      this.documentList = [];
      this.$axios
        .get("https://pedms5.petronas.com/DSR_E1Server/api/v2/SDA/Documents", {
          headers: {
            Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik5sZWh4aFB6VWY1V1ZVdkJMN0ZUTVVHZzYycyIsImtpZCI6Ik5sZWh4aFB6VWY1V1ZVdkJMN0ZUTVVHZzYycyJ9.eyJpc3MiOiJodHRwczovL3BlZG1zNS5wZXRyb25hcy5jb20vc2FtL29hdXRoLyIsImF1ZCI6IkZBMDZFODA5LTJBOEMtNDgzRC05MkJBLUNCODE0MkU5RDZDQiIsImV4cCI6MTY1OTcxMTgxOCwibmJmIjoxNjU5NjY4NjE4LCJjbGllbnRfaWQiOiJURVNUMSIsImluZ3IuY2xpZW50X2lkIjoiYjliNTk0OGMtMjc0My00N2U5LTg4ZTUtYmUyOWY1MjI1YTc1Iiwic2NvcGUiOlsiaW5nci5hcGkiLCJGQTA2RTgwOS0yQThDLTQ4M0QtOTJCQS1DQjgxNDJFOUQ2Q0IiXSwic3ViIjoidXNlcjEiLCJhdXRoX3RpbWUiOjE2NTk2Njg2MTgsImlkcCI6Imlkc3J2IiwibmFtZSI6IjEsIFVzZXIiLCJpbmdyLnVzZXJfaWQiOiJhYzAzNzlkMy1jNDZmLTQ5NjktODFiZi00M2RlYTYwZDRmZjIiLCJyb2xlIjoic3VwZXJ1c2VyIiwiaW5nci5zZXNzaW9uX2lkIjoiODVhODkwODUtODlhZi1lNDQ3LTg0M2MtZjhiZDU5ZGU4MmY0IiwiZW1haWwiOiJsZWUua2ltbG9vbmdAcGV0cm9uYXMuY29tIiwiYW1yIjpbInBhc3N3b3JkIl19.e1gPMUXIo5BUG8ZxSS3KB9rjYU4iGBTRt-aj2d1Su0lL1VLWgtSHwnp7bkr-IX3V-VMwiFFo62iPAabiv-SLtlFOp9tiLlnyMB1ia82IhVUofqot4jNKMnxZ967XWvmnUSQNmHRwTF37RdiMHgqddl60DJBWuuzdW0SuicRCUO-ZFftArfTHS0mbrxoPUQKiLxnaN8Zn_kYK0KOfOGR89x6_r0pks3KK8EgtQHhnL14SJfHVLIeXmm6QsFxZOf4Th9Myj4JtvKYT3p-8I-_IkbxmAhGo6oNUSSRges5S3UK0sFk2678zdqVUAAOT7ehXyA8vX-TBrKPNwrQ58xd5cQ`,
          },
        })
        .then((response) => {
          console.log(response.data.value);
          this.documentList = response.data.value;
          console.log("Document List", this.documentList);
          // this.documentList = response.data.value
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
